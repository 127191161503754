import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import HeroImage from "../components/Heros/HeroImage"
import MainContent from "../components/SingleMinistry/MainContent"

const singleMinistry = props => {
  const location = props.location
  const { seoInfo, hero, wfKids } = props.data

  return (
    <Layout location={location}>
      <SEO
        title={seoInfo.acf._swb_theme_meta_title}
        description={seoInfo.acf._swb_theme_description}
        metaImg={seoInfo.acf._swb_theme_image.localFile.publicURL}
        location={props.location.pathname}
      />
      <HeroImage hero={hero} />
      <MainContent wfKids={wfKids} />
    </Layout>
  )
}

export const ministryPostQuery = graphql`
  query ministryPost($id: Int!) {
    seoInfo: wordpressWpMinistry(wordpress_id: { eq: $id }) {
      acf {
        _swb_theme_meta_title
        _swb_theme_description
        _swb_theme_image {
          localFile {
            publicURL
          }
        }
      }
    }

    hero: wordpressWpMinistry(wordpress_id: { eq: $id }) {
      title
      acf {
        _wfc_min_hero_title
        _wfc_min_hero_img {
          alt_text
          localFile {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }

    wfKids: wordpressWpMinistry(wordpress_id: { eq: $id }) {
      acf {
        _wfc_wfk_title
        _wfc_wfk_kids_section {
          content
        }
        _wfc_button_required
        _wfc_button_text
        _wfc_button_url
        _wfc_wfk_images {
          image {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default singleMinistry
